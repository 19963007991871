import { ReactNode, createContext, useContext, useState } from 'react';
import { AppMode, FormContextType, FormEntity } from '../types';

const SHOW_MOCK = false;

const DEFAULT_VALUE: FormContextType = {
  form: SHOW_MOCK
    ? {
        name: 'Álex',
        ageMonths: 33,
        weightKg: 14,
        heightCm: 95,
        sex: 'm',
      }
    : {},
  setForm: () => undefined,
  showChart: AppMode.showForm,
  setShowChart: (value: AppMode) => undefined,
};

const FormContext = createContext(DEFAULT_VALUE);

export const Provider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<FormEntity>(DEFAULT_VALUE.form);
  const [showChart, setShowChart] = useState<AppMode>(AppMode.showForm);

  const setForm = (form: FormEntity) => {
    setValue(form);
  };

  const providerValue = {
    form: value,
    setForm,
    showChart,
    setShowChart,
  };

  return (
    <FormContext.Provider value={providerValue}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
