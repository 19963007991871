import { useFormContext } from '../../contexts/Provider';
import { images } from '../../images';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useLayoutEffect, useRef, useState } from 'react';

const DEBUG_SHOW_DOTS = false;

const babySize = 20;
const chartData = {
  chart_margin_left: 38,
  chart_margin_bottom: 56.3,
  step_x: 13.3,
  step_y: 5.3,
  firstPoint: 43,
};

const fillControlPoints = () => {
  const ret = [];
  for (var i = 0; i < 25; i++) {
    for (var j = 0; j < 35; j++) {
      ret.push({ ageMonths: i, weight: chartData.firstPoint + j });
    }
  }
  return ret;
};
const CONTROL_POINTS = fillControlPoints();

export const ChartHeight = () => {
  let intervalTick: NodeJS.Timer;

  const [baseCoords, setBaseCoords] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>({ x: -1, y: -1, width: -1, height: -1 });
  const chartRef = useRef<HTMLImageElement>(null);

  const { form } = useFormContext();
  const babySizeHalf = babySize / 2;

  const controlPoints = DEBUG_SHOW_DOTS ? CONTROL_POINTS : [];

  const calculatePosition = (ageMonths: number, heightCm: number) => {
    const x =
      baseCoords?.x +
      chartData.chart_margin_left +
      ageMonths * chartData.step_x -
      babySizeHalf;
    const y =
      baseCoords.y +
      baseCoords.height -
      chartData.chart_margin_bottom -
      (heightCm - chartData.firstPoint) * chartData.step_y -
      babySizeHalf;
    return { x, y };
  };

  const babyPosition = calculatePosition(form.ageMonths!, form.heightCm!);

  const measureChart = () => {
    if (chartRef.current) {
      const { x, y, width, height } = chartRef.current.getBoundingClientRect();
      setBaseCoords({ x, y, width, height });
      clearInterval(intervalTick);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', measureChart);
    intervalTick = setInterval(measureChart, 600);

    return () => {
      clearInterval(intervalTick);
      window.removeEventListener('resize', measureChart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Col>
        <div className="chart-height">
          <img
            ref={chartRef}
            src={form.sex === 'f' ? images.alturaNinas : images.alturaNinos}
            alt="Peso"
            className="chart"
          />

          {controlPoints.map((point, index) => {
            const { x, y } = calculatePosition(point.ageMonths, point.weight);
            return (
              <img
                src={images.DEBUG_DOT}
                className="debug-dot"
                alt="debug-dot"
                key={`debug-dot-${index}`}
                style={{
                  top: `${y}px`,
                  left: `${x}px`,
                }}
              />
            );
          })}

          <div
            className="baby"
            style={{
              top: `${babyPosition.y}px`,
              left: `${babyPosition.x}px`,
            }}
          />
        </div>
      </Col>
    </Container>
  );
};
