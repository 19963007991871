import './App.css';
import { Body } from './src/components/Body';

import { Provider } from './src/contexts/Provider';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div className="App">
      <Provider>
        <Body />
      </Provider>
    </div>
  );
};

export default App;
