import pesoNinas from './assets/pesoNina.png';
import pesoNinos from './assets/pesoNino.png';

import alturaNinas from './assets/alturaNina.png';
import alturaNinos from './assets/alturaNino.png';

import DEBUG_DOT from './assets/DEBUG_DOT.png';

export const images = {
  pesoNinas,
  pesoNinos,
  alturaNinas,
  alturaNinos,

  DEBUG_DOT,
};
