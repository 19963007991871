export type SexType = 'm' | 'f';

export type FormEntity = {
  name?: string;
  sex?: SexType;
  ageMonths?: number;
  weightKg?: number;
  heightCm?: number;
};

export type FormContextType = {
  form: Partial<FormEntity>;
  showChart: AppMode;
  setShowChart: (show: AppMode) => void;
  setForm: (form: FormEntity) => void;
};

export type PercentilType = {
  97: number[];
  50: number[];
  3: number[];
};

export enum AppMode {
  showForm,
  showWeight,
  showHeight,
}
export type AppModeType = keyof typeof AppMode;
