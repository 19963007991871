import { PercentilType, SexType } from '../types';
import { boy, girl } from './data';

const formatter = new Intl.NumberFormat('es');

export const usePercentil = (sex?: SexType, ageMonths?: number) => {
  const data = sex === 'm' ? boy : girl;

  const getHeightWeightText = (
    heightWeightData: PercentilType,
    heightOrWeight: number,
    postfix = ''
  ) => {
    if (ageMonths === undefined) return;

    const percentil50 = formatter.format(heightWeightData[50][ageMonths]);

    if (heightOrWeight < heightWeightData[3][ageMonths]) {
      return `Está por debajo del percentil 3 y de la media (${percentil50} ${postfix})`;
    } else if (heightOrWeight == heightWeightData[3][ageMonths]) {
      return `Está en el percentil 3, por debajo de la media (${percentil50}${postfix})`;
    } else if (heightOrWeight < heightWeightData[50][ageMonths]) {
      return `Está entre los percentiles 3 y 50, por debajo de la media (${percentil50} ${postfix})`;
    } else if (heightOrWeight == heightWeightData[50][ageMonths]) {
      return `Está en el percentil 50, justo en la media (${percentil50}${postfix})`;
    } else if (heightOrWeight < heightWeightData[97][ageMonths]) {
      return `Está entre los percentiles 50 y 97, por encima de la media (${percentil50} ${postfix})`;
    } else if (heightOrWeight == heightWeightData[97][ageMonths]) {
      return `Está en el percentil 97, por encima de la media (${percentil50} ${postfix})`;
    } else {
      return `Está por encima del percentil 97 y de la media (${percentil50} ${postfix})`;
    }
  };

  const getTexts = (name = 'Tu bebé', weight: number, heightCm: number) => {
    const weightText = getHeightWeightText(data.w, weight, 'kg');
    const heightText = getHeightWeightText(data.h, heightCm, 'cm');
    return {
      prefix_h: `${name} tiene ${ageMonths} meses y mide ${heightCm} cm. `,
      prefix_w: `${name} tiene ${ageMonths} meses y pesa ${weight} kg. `,
      w: weightText,
      h: heightText,
    };
  };

  return { getTexts };
};
