import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { ChartWeight } from './ChartWeight';
import { ChartHeight } from './ChartHeight';
import { usePercentil } from '../../data/usePercentil';
import { useFormContext } from '../../contexts/Provider';
import { AppMode } from '../../types';

export const Charts = () => {
  const { form, setShowChart, showChart } = useFormContext();
  const { getTexts } = usePercentil(form.sex, form.ageMonths);

  const texts =
    form.weightKg && form.heightCm
      ? getTexts(form.name, form.weightKg, form.heightCm)
      : { w: '', h: '', prefix_w: '', prefix_h: '' };

  const onMainButtonClick = () => {
    setShowChart(AppMode.showForm);
  };

  const onShowHeight = () => {
    setShowChart(
      showChart === AppMode.showHeight ? AppMode.showWeight : AppMode.showHeight
    );
  };

  return (
    <Container
      className="charts"
      style={{
        paddingLeft: '3rem',
        paddingRight: '3rem',
      }}
    >
      <Col lg={{ span: 12 }}>
        {showChart === AppMode.showWeight && (
          <>
            <div className="block">
              <h2>Peso</h2>
              <span>
                {texts.prefix_w}
                {texts.w}
              </span>
            </div>
            <ChartWeight />
          </>
        )}

        {showChart === AppMode.showHeight && (
          <>
            <div className="block">
              <h2>Longitud/estatura</h2>
              <span>
                {texts.prefix_h}
                {texts.h}
              </span>
            </div>
            <ChartHeight />
          </>
        )}
      </Col>

      <div className="toolbar">
        <Col>
          <Button variant="primary" size="sm" onClick={onMainButtonClick}>
            Calcular de nuevo
          </Button>
        </Col>
        <Col>
          <Button variant="primary" size="sm" onClick={onShowHeight}>
            {showChart === AppMode.showHeight
              ? 'Calcular peso'
              : 'Calcular longitud'}
          </Button>
        </Col>
      </div>
    </Container>
  );
};
