import { PercentilType } from '../types';

const heightBoy97 = [
  53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4,
  53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4,
  53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 53.4, 100.8, 101.5, 102.3, 103.1,
];
const heightBoy50 = [
  49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9,
  49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9,
  49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 49.9, 94.1, 94.8, 95.4, 96.1,
];
const heightBoy3 = [
  46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3,
  46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3,
  46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 46.3, 87.4, 88.0, 88.5, 89.1,
];
/////
const weightBoy97 = [
  97, 4.3, 5.7, 7.0, 7.9, 8.6, 9.2, 9.7, 10.2, 10.5, 10.9, 11.2, 11.5, 11.8,
  12.1, 12.4, 12.7, 12.9, 13.2, 13.5, 13.7, 14.0, 14.3, 14.5, 14.8, 15.1, 15.3,
  15.6, 15.9, 16.1, 16.4, 16.6, 16.9, 17.1, 17.3, 17.6, 17.8, 18.0,
];
const weightBoy50 = [
  3.3, 4.5, 5.6, 6.4, 7.0, 7.5, 7.9, 8.3, 8.6, 8.9, 9.2, 9.4, 9.6, 9.9, 10.1,
  10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.8, 12.0, 12.2, 12.4, 12.5, 12.7,
  12.9, 13.1, 13.3, 13.5, 13.7, 13.8, 14.0, 14.2, 14.3,
];
const weightBoy3 = [
  2.5, 3.4, 4.4, 5.1, 5.6, 6.1, 6.4, 6.7, 7.0, 7.2, 7.5, 7.7, 7.8, 8.0, 8.2,
  8.4, 8.5, 8.7, 8.9, 9.0, 9.2, 9.3, 9.5, 9.7, 9.8, 10.0, 10.1, 10.2, 10.4,
  10.5, 10.7, 10.8, 10.9, 11.1, 11.2, 11.3, 11.4,
];
/////
const heightGirl97 = [
  52.7, 57.4, 60.9, 63.8, 66.2, 68.2, 70.0, 71.6, 73.2, 74.7, 76.1, 77.5, 78.9,
  80.2, 81.4, 82.7, 83.9, 85.0, 86.2, 87.3, 88.4, 89.4, 90.5, 91.5, 92.15, 92.8,
  93.7, 94.6, 95.6, 96.4, 97.3, 98.2, 99.0, 99.8, 100.6, 101.4, 102.2,
];
const heightGirl50 = [
  49.1, 53.7, 57.1, 59.8, 62.1, 64.0, 65.7, 67.3, 68.7, 70.1, 71.5, 72.8, 74.0,
  75.2, 76.4, 77.5, 78.6, 79.7, 80.7, 81.7, 82.7, 83.7, 84.6, 85.5, 86.05, 86.6,
  87.4, 88.3, 89.1, 89.9, 90.7, 91.4, 92.2, 92.9, 93.6, 94.4, 95.1,
];
const heightGirl3 = [
  45.6, 50.0, 53.2, 55.8, 58.0, 59.9, 61.5, 62.9, 64.3, 65.6, 66.8, 68.0, 69.2,
  70.3, 71.3, 72.4, 73.3, 74.3, 75.2, 76.2, 77.0, 77.9, 78.7, 79.6, 79.95, 80.4,
  81.2, 81.9, 82.6, 83.4, 84.0, 84.7, 85.4, 86.0, 86.7, 87.3, 87.9,
];
/////
const weightGirl97 = [
  4.2, 5.4, 6.5, 7.4, 8.1, 8.7, 9.2, 9.6, 10.0, 10.4, 10.7, 11.0, 11.3, 11.6,
  11.9, 12.2, 12.5, 12.7, 13.0, 13.3, 13.5, 13.8, 14.1, 14.3, 14.6, 14.9, 15.2,
  15.4, 15.7, 16.0, 16.2, 16.5, 16.8, 17.0, 17.3, 17.6, 17.8,
];
const weightGirl50 = [
  3.2, 4.2, 5.1, 5.8, 6.4, 6.9, 7.3, 7.6, 7.9, 8.2, 8.5, 8.7, 8.9, 9.2, 9.4,
  9.6, 9.8, 10.0, 10.2, 10.4, 10.6, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1,
  12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5, 13.7, 13.9,
];
const weightGirl3 = [
  2.4, 3.2, 4.0, 4.6, 5.1, 5.5, 5.8, 6.1, 6.3, 6.6, 6.8, 7.0, 7.1, 7.3, 7.5,
  7.7, 7.8, 8.0, 8.2, 8.3, 8.5, 8.7, 8.8, 9.0, 9.2, 9.3, 9.5, 9.6, 9.8, 10.0,
  10.1, 10.3, 10.4, 10.5, 10.7, 10.8, 11.0,
];
/////
export const boy = {
  w: {
    97: weightBoy97,
    50: weightBoy50,
    3: weightBoy3,
  } satisfies PercentilType,
  h: {
    97: heightBoy97,
    50: heightBoy50,
    3: heightBoy3,
  } satisfies PercentilType,
};
export const girl = {
  w: {
    97: weightGirl97,
    50: weightGirl50,
    3: weightGirl3,
  } satisfies PercentilType,
  h: {
    97: heightGirl97,
    50: heightGirl50,
    3: heightGirl3,
  } satisfies PercentilType,
};
