import { ChangeEvent } from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useFormContext } from '../contexts/Provider';
import { AppMode } from '../types';

export const FormContent = () => {
  const { form, setForm, setShowChart } = useFormContext();

  const setFormState = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const key = target.name;
    setForm({ ...form, [key]: value });
  };

  const setSex = (sex: 'm' | 'f') => (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, sex });
  };

  const onShowWeight = () => {
    onMainButtonClick(AppMode.showWeight);
  };
  const onShowHeight = () => {
    onMainButtonClick(AppMode.showHeight);
  };

  const onMainButtonClick = (nextStep: AppMode) => {
    if (form.ageMonths && form.weightKg && form.heightCm && form.sex) {
      if (form.ageMonths > 36) alert('Lo sentimos, la edad máxima es 36 meses');
      if (form.heightCm > 105) alert('Lo sentimos, la altura máxima es 105cm');
      if (form.weightKg > 20) alert('Lo sentimos, el peso máximo es 20kg');
    } else {
      alert('Por favor, rellena todos los campos');
      return;
    }

    setShowChart(nextStep);
  };

  return (
    <Container
      className="sm"
      style={{
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '2rem',
      }}
    >
      <Col xs={{ span: 12 }}>
        <Form>
          <Container style={{ paddingLeft: 0, paddingBottom: '30px' }}>
            <h2>Indícanos los datos de tu bebé</h2>
            <span className="description">
              Comprueba en qué percentil está tu bebé, según su peso y longitud,
              basado en los estándares de la OMS. En las gráficas de peso y
              longitud se mostrará el percentil 50, media de todos los bebés, el
              rango superior del percentil 97 y el inferior del percentil 3.
            </span>
          </Container>

          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              name="name"
              maxLength={45}
              defaultValue={form.name}
              onChange={setFormState}
              placeholder="Nombre"
            />
          </Form.Group>

          <Row className="options">
            <Col xs="6" md="5">
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>Edad (meses)</Form.Label>
                <Form.Control
                  name="ageMonths"
                  maxLength={3}
                  defaultValue={form.ageMonths?.toString()}
                  onChange={setFormState}
                  placeholder="Edad (meses)"
                />
              </Form.Group>
            </Col>

            <Col xs="3" md="2" style={{ paddingTop: 9 }}>
              <Form.Check
                label="Niña"
                name="sex"
                type="radio"
                id="f"
                defaultChecked={form.sex === 'f'}
                onChange={setSex('f')}
              />
            </Col>
            <Col xs="3" md="2" style={{ paddingTop: 9 }}>
              <Form.Check
                label="Niño"
                name="sex"
                type="radio"
                id="m"
                defaultChecked={form.sex === 'm'}
                onChange={setSex('m')}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>Peso (kg)</Form.Label>
                <Form.Control
                  name="weightKg"
                  maxLength={2}
                  defaultValue={form.weightKg?.toString()}
                  onChange={setFormState}
                  placeholder="Peso (kg)"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>Altura (cm)</Form.Label>
                <Form.Control
                  name="heightCm"
                  maxLength={3}
                  defaultValue={form.heightCm?.toString()}
                  onChange={setFormState}
                  placeholder="Altura (cm)"
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="toolbar">
            <Col>
              <Button variant="primary" size="sm" onClick={onShowWeight}>
                Calcular peso
              </Button>
            </Col>
            <Col>
              <Button variant="primary" size="sm" onClick={onShowHeight}>
                Calcular longitud
              </Button>
            </Col>
          </div>
        </Form>
      </Col>
    </Container>
  );
};
